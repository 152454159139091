import * as React from 'react'
import { Row, Col, Card, Form, Input, Button } from 'antd'

import IoCContainer from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'

import { INotifyService } from '../../services/shared/NotifyService'
import { IAuthenticationService } from '../../services/AuthenticationService'

import info_grey from '../../content/info_grey.svg'
import attention from '../../content/attention.svg'
class LoginState {
    mail: string
    password: string
    code: string
    IsAccountVerified: boolean
    IsCodeRequested: boolean
}


export class PageLogin extends React.Component<any, LoginState> {

    private authenticationService: IAuthenticationService
    private notifyService: INotifyService

    constructor(props) {
        super(props)

        this.state = new LoginState();

        this.authenticationService = IoCContainer.get(SERVICE_IDENTIFIER.AUTHENTICATION_SERVICE);
        this.notifyService = IoCContainer.get(SERVICE_IDENTIFIER.NOTIFY);
    }

    onHelp = () => {
        throw new Error('Method not implemented.')
    }

    richiediPassword =async () => {
        let resp = await  this.authenticationService.RequestPassword(this.state.mail);
        if (resp.IsValid === true) {
            this.notifyService.success("La richiesta è stata inviata correttamente. Riceverai la nuova password entro pochi secondi tramite SMS");
        }
    }

    login = async () => {
        var resp = await this.authenticationService.Login(this.state.mail, this.state.password)
        if (resp.IsValid === true) {
            this.setState({ IsAccountVerified: true })
        }
    }

    verifica2fa = async () => {
        await this.authenticationService.Verifica2fa(this.state.mail, this.state.password, this.state.code)
    }

    request2fa = async () => {
        var resp = await this.authenticationService.Request2FA(this.state.mail)
        if (resp.IsValid) this.setState({IsCodeRequested : true})   
    }

    render() {
        return (
            <Row>
                <Col lg={{ offset: 8, span: 8 }} sm={{ offset: 6, span: 12 }} xs={{ offset: 2, span: 20 }}>
                    <Card style={{ marginTop: '24px' }}>
                            {!this.state.IsAccountVerified
                            ? <div>
                                <Row>
                                    <Col >
                                        <p className="Bold20Credem400">Accesso</p>
                                    </Col>
                                </Row>
                                <Row >

                                    <Col span="24">
                                        <Form layout='vertical'>
                                            <Form.Item label="Email"
                                                tooltip={{
                                                    title: 'Inserisci l’indirizzo email che hai fornito al tuo gestore di filiale per l’apertura della richiesta di mutuo',
                                                    icon: <img data-type="button" src={info_grey} height="15" />
                                                }}>
                                                <Input type="email" name="mail" value={this.state.mail} placeholder="Inserisci il tuo indirizzo email"
                                                        onChange={e => this.setState({ mail: e.target.value })} />
                                            </Form.Item>
                                            <Form.Item label="Codice di accesso"
                                                tooltip={{
                                                    title: 'Inserisci il codice di sicurezza che hai ricevuto tramite SMS. Se non hai a disposizione il codice di sicurezza puoi chiederne uno nuovo selezionando la voce “Richiedi un nuovo codice di accesso”',
                                                    icon: <img data-type="button" src={info_grey} height="15" />
                                                }}>
                                                <Input type="password" name="otp" value={this.state.password} placeholder="Codice di sicurezza"
                                                       onChange={e => this.setState({ password: e.target.value })} />
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row justify="end" >
                                    <Col><a href="#" onClick={this.richiediPassword}>Richiedi un nuovo codice di accesso</a></Col>
                                </Row>
                                <br />
                                <br />
                                <Button type="primary" shape="round" block onClick={this.login}>Accedi</Button>
                            </div>
                            :
                            <div>
                                <Row>
                                    <Col >
                                        <p className="Bold20Credem400">Avvisi</p>
                                    </Col>
                                </Row>
                                <Row justify="center"  >
                                    <Col span="24" className="attention-container">
                                        <img data-type="button" src={attention} className="attention-image" />
                                    </Col>
                                    <Col span="24">
                                        <span className="Medium15Credem400">Ciao!<br /><br /> Per accedere richiedi il codice che riceverai via e-mail e inseriscilo qui sotto. </span>
                                    </Col>
                                </Row>
                                <br />
                                <br />
                                <Row justify="center" >
                                    <Col span="16"><Button disabled={this.state.IsCodeRequested}  type="ghost" shape="round" block onClick={this.request2fa}>Richiedi codice via e-mail</Button></Col>
                                    <Col span="8"><Input name="2facode" placeholder="Inserisci qui" value={this.state.code}
                                                          onChange={e => this.setState({ code: e.target.value })}/></Col>
                                </Row>
                                {this.state.IsCodeRequested &&
                                    <Row>
                                        <Col span="24" ><span className="Medium15Credem400"> Il tuo codice è stato inviato alla mail indicata in fase di login </span></Col>
                                    </Row>
                                }
                                
                                <br />
                                <br />

                                <Button type="primary" shape="round" block onClick={this.verifica2fa}>ACCEDI</Button>

                            </div>
                            }
                            
                    </Card>
                </Col>
            </Row>
        )
    }
}
    